<template>
  <div>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title
          v-if="collegeData"
          class="headline"
        >
          <v-icon>mdi-google-maps</v-icon> {{collegeData[`name_${lang}`]}}
        </v-list-item-title>
        <v-list-item-subtitle class="pl-7">
          {{$moment(currentdate).format("dddd Do MMMM YYYY")}}
          <span>
            <v-icon>mdi-clock-outline</v-icon>
            {{$t('base.latestUpdate')+' '+$moment(currentdate).format("H:mm:ss")}}
          </span>

        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-row>
      <v-col
        cols="12"
        sm="6"
      >

        <apexchart
          height="250"
          :options="chartOptions"
          :series="series"
          ref="overallPercentage"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
      >

        <div class="d-inline-block text-center">
          <p class="text-h6">
            <v-icon
              large
              class="mr-1"
            >mdi-account-check-outline</v-icon>
            <span>{{$t('app.countPeopleNow')}}</span>
          </p>
          <h1 class="text-h2 transition-swing font-weight-black" :style="'color:'+data.color">
            {{Number(data.current).toLocaleString('th')}}
          </h1>
        </div>

        <div class="d-inline-block float-right pr-2 text-center">
          <p class="text-h6">
            <v-icon
              large
              class="mr-1 "
            >mdi-account-cancel-outline</v-icon>
            <span>{{$t('app.checkinLmit')}}</span>
          </p>
          <h1 class="text-h2 transition-swing font-weight-black error--text">{{Number(data.limit).toLocaleString('th')}}</h1>
        </div>

        <realtime-chart-by-type
          v-if="charts"
          :type="99"
          :charts="charts"
          :categories="categories"
          v-model="chartUpdate"
          :dark="dark"
          :height="150"
          strokestyle="straight"
          :colors="['#008bf2', '#ff5e3a']"
        />
      </v-col>

    </v-row>

  </div>
</template>
<script>
import {
  reactive,
  toRefs,
  defineComponent,
  ref,
  watch
} from "@vue/composition-api";
// import { getApexChartThemeBgMode } from "@/plugins/util";
export default defineComponent({
  components: {
    apexchart: () => import("vue-apexcharts"),
    RealtimeChartByType: () => import("./RealtimeChartByType")
  },
  props: {
    value: 0,
    data: {
      type: Object,
      default: null
    },
    currentdate: {
      type: String,
      default: null
    },
    dark: {
      type: Boolean,
      default: false
    },
    collegeData: {
      type: Object,
      default: null
    },
    lang: {
      type: String,
      default: null
    },

    chartUpdate: {
      type: Object,
      default: null
    },
    charts: {
      type: Object,
      default: null
    },
    categories: {
      type: Array,
      default: null
    }
  },
  setup(props, { refs, root }) {
    const { $i18n } = root;
    const series = ref([0]);
    const chartData = reactive({
      chartOptions: {
        // theme: {
        //   mode: props.dark ? "dark" : "light"
        // },
        chart: {
          type: "radialBar",
          toolbar: {
            show: false
          },
          // background: props.dark ? "transparent" : "#fff"
          // background: props.dark ? "#000000" : "#fff"
        },

        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: "70%",
              background: "#fff",
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: "front",
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24
              }
            },
            track: {
              background: "#fff",
              strokeWidth: "10%",
              margin: -5, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,//4
                opacity: 0.35,//0.35
              }
            },

            dataLabels: {
              show: true,
              name: {
                offsetY: 60,
                show: true,
                color: "#888",
                fontSize: "15px"
              },
              value: {
                formatter: function(val) {
                  // return parseInt(val);
                  return parseFloat(val);
                },
                offsetY: -10,
                color: "#111",
                fontSize: "46px",
                fontWeight: 600,
                show: true
              }
            }
          }
        },
        fill: {
          type: "gradient", //fill, gradient
          // colors: "#00D1B2",
          gradient: {
            shade: "light",
            type: "horizontal",
            shadeIntensity: 0.5,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: "round"
        },
        labels: [$i18n.t("base.percentage")],
        colors: [props.data.color]
      }
    });
    watch(
      () => props.value,
      (val, oldVal) => {
        if (val === oldVal) return;

        let col = "";
        if (val >= 0 && val <= 49.99) {
          col = "#23D160";
        } else if (val >= 50 && val <= 59.99) {
          col = "#3273DC";
        } else if (val >= 60 && val <= 69.99) {
          col = "#FFC107";
        } else if (val >= 70 && val <= 79.99) {
          col = "#FF9F43";
        } else if (val >= 80 && val <= 89.99) {
          col = "#FF5722";
        } else {
          col = "#F44336";
        }

        // for (let i = chartData.chartOptions.colors.length - 1; i >= 0; i--) {
        //   chartData.chartOptions.colors.splice(i, 1);
        // }
        // chartData.chartOptions.colors.push(col);

        // chartData.chartOptions.colors = [col];
        series.value = [val];
        if (refs.overallPercentage) {
          refs.overallPercentage.updateSeries(series.value, false, true);
          // refs.overallPercentage.updateOptions(chartData.chartOptions,false, true);
          refs.overallPercentage.updateOptions({
            colors: [col]
          });
        }
      }
    );

    // watch(
    //   () => props.dark,
    //   val => {
    //     if (!refs.overallPercentage) {
    //       return;
    //     }
    //     updateTheme(val);
    //   }
    // );
    // const updateTheme = dark => {
    //   refs.overallPercentage.updateOptions({
    //     chart: { background: dark ? "#000000" : "#fff" },
    //     theme: { mode: dark ? "dark" : "light" }
    //   });
    // };
    return {
      ...toRefs(chartData),
      series
    };
  }
});
</script>